/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-20 20:49:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-03-24 23:31:28
 * @FilePath: /chat_gpt/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/css/style.scss'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import hljs from "highlight.js";
import javascript from 'highlight.js/lib/languages/javascript';
import css from 'highlight.js/lib/languages/css';
import html from 'highlight.js/lib/languages/xml';
import python from 'highlight.js/lib/languages/python';
// import * as languages from 'highlight.js/lib/languages';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('css', css);
hljs.registerLanguage('html', html);
hljs.registerLanguage('python', python);


import { REQUEST } from '@/api/http'
Vue.config.productionTip = false
Vue.prototype.$https = REQUEST.POST
Vue.prototype.$httpsGET = REQUEST.GET
Vue.use(ElementUI)

// Vue.use(VueCodeMirror)
// Vue.use(hljsVuePlugin)

// Vue.directive("highlight", function(el) {
//   let blocks = el.querySelectorAll("pre code");
//   blocks.forEach(block => {
//     hljs.highlightBlock(block);
//   });
// });

// Vue.prototype.$hljs = hljs;

Vue.use(hljs)
hljs.configure({
  ignoreUnescapedHTML:true,
});
//封装成一个指令,highlight是指令的名称
// Vue.directive('highlight', el => {
//   let blocks = el.querySelectorAll('pre code')
//   blocks.forEach(block => {
//     // 创建ol标签元素
//     let ol = document.createElement('ol')
//     // 2.根据换行符获取行数，根据获取的行数生成行号
//     let rowCount = block.outerHTML.split('\n').length
//     for (let i = 1; i < rowCount; i++) {
//       // 创建li标签元素
//       let li = document.createElement('li')
//       let text = document.createTextNode(i)
//       // 将生成的行号添加到li标签中
//       li.appendChild(text)
//       // // 将li标签添加到ol标签中
//       // ol.appendChild(li)
//     }
//     // 为ol标签添加class名
//     ol.className = 'pre-numbering'
//     block.parentNode.appendChild(ol)
//     hljs.highlightElement(block)
//   })
// })

Vue.directive('highlight', {
  deep: true,
  bind: function (el, binding) {
    let blocks = el.querySelectorAll('pre code')
  blocks.forEach(block => {
    // 创建ol标签元素
    let ol = document.createElement('ol')
    // 2.根据换行符获取行数，根据获取的行数生成行号
    let rowCount = block.outerHTML.split('\n').length
    for (let i = 1; i < rowCount; i++) {
      // 创建li标签元素
      let li = document.createElement('li')
      let text = document.createTextNode(i)
      // 将生成的行号添加到li标签中
      li.appendChild(text)
      // // 将li标签添加到ol标签中
      // ol.appendChild(li)
    }
    // 为ol标签添加class名
    ol.className = 'pre-numbering'
    block.parentNode.appendChild(ol)
    let lis = document.getElementsByClassName('language-vue')
    for(var i= 0; i < lis.length; i++){
      lis[i].className = "language-javascript"
    }
    // document.getElementsByClassName('language-vue')[0].className ='language-javascript' 
    // console.log(block)
    hljs.highlightElement(block)
  })

    // const targets = el.querySelectorAll('pre code');
    // console.log(targets)
    // for (const target of targets) {
    //   if (binding.value) {
    //     target.textContent = binding.value;
    //   }
    //   console.log(target)
    //   hljs.highlightElement(target);
    // }
  },
  componentUpdated: function (el, binding) {
    let blocks = el.querySelectorAll('pre code')
    blocks.forEach(block => {
    // 创建ol标签元素
    let ol = document.createElement('ol')
    // 2.根据换行符获取行数，根据获取的行数生成行号
    let rowCount = block.outerHTML.split('\n').length
    for (let i = 1; i < rowCount; i++) {
      // 创建li标签元素
      let li = document.createElement('li')
      let text = document.createTextNode(i)
      // 将生成的行号添加到li标签中
      li.appendChild(text)
      // // 将li标签添加到ol标签中
      // ol.appendChild(li)
    }
    // 为ol标签添加class名
    ol.className = 'pre-numbering'
    block.parentNode.appendChild(ol)

    let lis = document.getElementsByClassName('language-vue')
    for(var i= 0; i < lis.length; i++){
      lis[i].className = "language-javascript"
    }
    hljs.highlightElement(block)
  })

    // const targets = el.querySelectorAll('pre code');
    // for (const target of targets) {
    //   if (binding.value) {
    //     target.textContent = binding.value;
    //   }
    //   hljs.highlightBlock(target);
    // }
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
