<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-20 20:49:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-03-21 20:35:30
 * @FilePath: /chat_gpt/src/App.vue
-->
<template>
  <div id="app" :class="isClass === 'ios' ? 'QYZXIOS' : 'QYZX'">
    <router-view />
  </div>
</template>
<script>
export default {
    data() {
    return {
      isClass: '',
    }
  },
  created() {
    if (this._isMobile()) {
      window.localStorage.setItem('phone', true)
    } else {
      window.localStorage.setItem('phone', false)
    }
  },
  methods: {
    _isMobile() {
      // var u = navigator.userAgent;
      // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      // if (isAndroid) {
      //     this.isClass = "android"
      //     console.log("android>>>>");
          
      // }
      // if (isiOS) {
      //     console.log("ios>>>>");
          
      //       this.isClass = "ios"
      // }
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  }
}
</script>
<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  // height: 100vh;
  // width: 100vw;
}
.QYZXIOS{
  height: calc(100vh - 75px);
  width: 100vw;
}
.QYZX{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-shrink: 0;
}
// #app{
//   width: 100%;
//   height: 100%;
// }
.el-scrollbar .el-scrollbar__bar {
  opacity: 1 !important;
}
.el-scrollbar {
    > .el-scrollbar__bar {
        opacity: 1 !important;
    }
}   
</style>
