
import axios from 'axios'
// export const baseUrl = 'http://47.254.43.114:8838'
// export const baseUrl = 'http://192.168.31.159:8838'
export const baseUrl = 'https://550w.ai/api'
let stripeKey =  baseUrl === 'https://550w.ai/api'?'pk_live_51N5iKTH3eoAtyIl671Co4fz9wV5EjmkMBicx5HMybPVmcDbCbFZm3dr7i2aWYYsjHLQ5VosVokfMke079RZN9Br700yQhTEqBI':'pk_test_51N5iKTH3eoAtyIl6Fkz9UcVikgiTKePl56Gx0vDhrAKQkjdrbdDjpuTjCplah9AsspFgTq7IpTs6DYc6XAGcRJAJ00E1Y5ubUy'

export const HTTP_URI = {
  BASEURL: baseUrl,
  STRIPEKEY: stripeKey,
  REGISTER: baseUrl + '/user/token/register',
  LOGIN: baseUrl + '/login',
  LOGOUT: baseUrl + '/logout',
  REGIEST: baseUrl + '/addEntity', //注册
  GETVERCODE: baseUrl + '/verifyCode', 
  DELCHAT: baseUrl + '/chatUser/deleteUserChat', //删除用户聊天记录
  GETUSERLIST: baseUrl +'/chatUser/getUserChatList',//获取用户聊天记录

  TRANSDATA: baseUrl +'/chatNew/sse',  //聊天时参数传递
  // CLOSESSE:  baseUrl +'/chatNew/closeSse',//关闭聊天

  CHATPRESETLIST: baseUrl +'/chatUser/getUserChatPreset',//查询用户聊天背景预设
  DELCHATPRESET: baseUrl +'/chatUser/delUserChatPreset', //根据ID删除用户聊天背景预设
  ADDCHATPRESET: baseUrl+'/chatUser/saveUserChatPreset', //新增用户聊天背景预设

  GETGOLD: baseUrl+'/chatUser/getUserGold', //获取当前用户的金币
  GETCONSUMGOLD: baseUrl+'/chatUser/getUserConsumeGold', //获取当前用户本次聊天所消耗的金币

  PAYCHATGOLD: baseUrl+'/chatUser/payUserChatGold', //充值当前用户的金币 --用卡密的方式
  PAYCHATGOLDSTRIPE: baseUrl+'/chatUser/payUserChatGoldStripe', //充值当前用户的金币 --国外支付

  GETPAY: baseUrl+'/chatUser/getStripePaymentIntents',



  PRODUCT: baseUrl + '/product/list',
  USERHOME: baseUrl + '/user/token/home',
  CHAT: baseUrl + '/v1/chat/turbo',
  getType: baseUrl + '/user/token/getType',
  REPEST: baseUrl + '/use/log/updateLog',
  ADD: baseUrl + '/use/log/resetLog',
  NOTICELIST: baseUrl + '/announcement/queryPage',
  PAY: baseUrl + '/order/create'
}
// export default { HTTP_URI, baseUrl }
